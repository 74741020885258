@import '../../../media.module.scss';

.Actions {
  cursor: pointer;
  text-align: center !important;
}

.statusColorGray {
  width: 70px;
  padding: 3px 7px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $neutral4;
  background-color: $neutral3;
}
.statusColorYellow {
  width: 70px;
  padding: 3px 7px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #ffffd1;
  background-color: #ffffd1;
}
.statusColorGreen {
  width: 70px;
  padding: 3px 7px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #e4ffad;
  background-color: #e4ffad;
}
