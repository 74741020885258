@import '../../../media.module.scss';

.container {
  background-color: $neutral4;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  p {
    margin-right: 10px;
  }
}
