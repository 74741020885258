@import '../../../media.module.scss';

.container {
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: row;
}

.section {
  flex: 1;
}

.data {
  padding: 40px 40px 0px 40px;
  max-width: 500px;
}

.subTitle {
  margin-bottom: 20px !important;
}

.divider {
  border-top: 1px solid $neutral4;
  margin-top: 40px;
}

.dataItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  p {
    margin-left: 10px;
  }
}
