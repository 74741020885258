@import '../../media.module.scss';

.LoginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
}

.TitleContainer {
  flex: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
}
.TitleContent {
  display: flex;
  flex-direction: column;
}
.Logo {
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}
.ImageRef {
  width: 100%;
  max-width: 300px;
  margin-bottom: 50px;
}

.FormContainer {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
}

@include media('<desktop') {
  .LoginContainer {
    flex-direction: column-reverse;
    overflow-y: auto;
  }
  .TitleContainer {
    display: none;
  }
}
