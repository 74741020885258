@import '../../../media.module.scss';

.FormTrakingContainer {
  border: 1px solid $neutral4;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: row;

  button {
    margin-top: 15px;
    width: 100%;
  }
}
