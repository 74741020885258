@import '../../media.module.scss';

.NotificationsContainer {
  padding: 20px;
  box-sizing: border-box;
  > :first-child {
    margin-bottom: 20px;
  }
}

.NotificationsContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.Block {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border-top: 1px solid $neutral4;
  gap: 20px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 15px 0;
  > button {
    width: 170px;
  }
  gap: 20px;
}

.TextFliedWithButton {
  display: flex;
  align-items: stretch;
  gap: 5px;
}

.InputImg {
  margin-right: 10px;
  width: 32px;
}

@include media('<desktop') {
  .Block {
    flex-direction: column;
  }
}
