@import '../../../media.module.scss';

.SelectionContainer {
  flex: 1;
  display: flex;
  overflow-x: auto;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.CloseIcon {
  position: absolute;
  top: -4px;
  left: calc(100% - 46px);
}

.CardButton {
  height: 400px;
  min-width: 200px;
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  > img {
    height: 350px;
    border-radius: 10px;
  }
}

.Separator {
  border-right: 1px solid $primary;
  height: 410px;
}

.SelectedCard {
  background-color: rgba($primary, 0.25);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.GoBackAction {
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  cursor: pointer;
  > span:first-child {
    position: relative;
    top: 3px;
  }
}
