@import '../../media.module.scss';

.Loading {
  background-color: $primary;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 150px;
  }
}

.LoadingFlex {
  width: 100%;
  height: 100%;
}

.LoadingTransparent {
  background-color: transparent !important;
}

.LoadingWhite {
  background-color: white;
}

.Text {
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  font-weight: 100;
  text-align: center;
}

.TextWhite {
  color: $primary;
}
