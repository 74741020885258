@import '../../media.module.scss';

.ViewerContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

.ObjectProperties {
  width: 100%;
  max-width: 700px;
}

.ErrorFile {
  padding: 10px;
  box-sizing: border-box;
}

.DownloadButton {
  margin-top: 5px;
  margin-bottom: 10px;
}