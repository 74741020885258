@import '../../media.module.scss';

.CardContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $neutral0;
  height: fit-content;
}

.Header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Forms {
  flex: 1;
  textarea {
    height: 87px !important;
  }
}
