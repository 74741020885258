@import '../../../media.module.scss';

.TagBorder {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid $neutral5;
  position: relative;
  > span:first-child {
    position: absolute;
    top: -12px;
    left: 8px;
    padding-left: 5px;
    padding-right: 3px;
  }
  .tagInput {
    border: none;
    text-decoration: none;
    width: 100%;
  }
}

.ControlInputContainer {
  display: flex;
}

.TagBorder:hover {
  border: 2px solid $primary;
  > span:first-child {
    color: $primary;
  }
  .tagInput {
    outline: none;
    border: 0;
  }
}

.TagBorder:not(:focus) {
  .tagInput {
    outline: none;
    border: 0;
  }
}

.TagBorder:focus {
  .tagInput {
    outline: none;
    border: 0;
  }
}

.StackTags {
  margin-top: 0;
}

.TagBorderError {
  border: 2px solid $error5 !important;
  > span:first-child {
    color: $error5 !important;
  }
}

.ErrorLabel {
  position: relative;
  left: 10px;
}
