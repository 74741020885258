@import '../../../media.module.scss';

.NotificationsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.TabFormColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.TabForm {
  width: 100%;
  display: flex;
  gap: 30px;
}

.LateralInputs {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
}

.RadioTypes {
  border: 1px solid $neutral5 !important;
  border-radius: 5px;
  padding: 10px !important;
  box-sizing: border-box;
  height: max-content;
  position: relative;
  .RadioTypesTitle {
    position: absolute;
    color: $neutral6;
    top: -10px;
    font-size: 13px;
    background-color: $neutral0;
    padding-left: 4px;
    padding-right: 3px;
  }
}

.InputContent {
  flex: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.TopicForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  .SelectRow {
    display: flex;
    gap: 20px;
    position: relative;
    margin-top: 20px;
    > span {
      position: absolute;
      top: -18px;
      left: 14px;
    }
  }
}

.TagsForm {
  display: flex;
  flex-direction: column;
}

.ButtonSelector {
  margin-top: 15px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  > img {
    border-radius: 10px;
    width: 150px;
  }
}

.SubmitBtn {
  width: 150px;
}
