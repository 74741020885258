@import "../../media.module.scss";

.DashboardContainer{
    display: flex;
}

.ChildrenContainer {
    padding-top: 64px;
    height: 100vh;
    width: calc(100% - 62px);
    box-sizing: border-box;
    overflow: scroll;
}

.ListButtons {
    > div {
        padding-left: 21px;
    }
}

.Drawer {
    
}

@include media("<tablet") {
    .ListButtons {
        > div {
            padding-left: 13px;
        }
    }
  }