@import '../../../media.module.scss';

.IncentivesTab {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  button {
    width: 100%;
    max-width: 180px;
    margin: 10px 0 0 0;
    align-self: flex-end;
  }
}
.IncentivesTable {
  width: 100%;
  margin: 10px 0;
  overflow: scroll;
}
.TableHead {
  background-color: $neutral3;
}
.Actions {
  cursor: pointer;
  text-align: center !important;
}
