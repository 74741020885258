@import '../../../media.module.scss';

.container {
  padding: 20px 20px 0px 20px;
}

.leadData {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 50px;
}

.leadDataText {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  margin-right: 20px;
}

.leadDataIcon {
  margin-right: 10px;
  font-weight: 500;
}

.quotationContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.configPanel {
  width: 180px;
  padding-right: 15px;
}

.informationPanel {
  width: calc(100% - 200px);
  padding-top: 6px;
  padding-bottom: 100px;
}

.detailsPanel {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

.detailsPanelScroll {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
