@import '../../media.module.scss';

.content {
  padding: 20px;
}
.controls {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
}
.accordion {
  padding: 10px 0px 0px 0px;
}
.container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 20px;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid grey;
}
.subBrand {
  display: flex;
  align-items: center;
}
.subContainer {
  flex: 1;
}
.subContainerTitle {
  margin-bottom: 10px !important;
  padding-left: 15px;
  color: $primary;
}
.matchVersion {
  background-color: $success3;
}
.homologCard {
  margin-top: 20px;
  margin-bottom: 100px;
  padding: 20px 0px;
  display: flex;
  gap: 20px;
  align-items: center;
  border-top: 1px solid grey;
}
.homologSubCard {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  p {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
  }
  p > i {
    width: 100px !important;
  }
}
.actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
