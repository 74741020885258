@import '../../../media.module.scss';

.ContactLeadContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.Header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid $neutral4;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.SharedLinks {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  > div {
    max-width: 540px;
  }
}

.NewContactButton {
  width: 200px !important;
}

.TrackingElements {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  .TrackingTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ContactTracking {
    width: 100%;
    margin: 20px 0;
  }
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $neutral0;
}

.Quotation {
  width: 100%;
  max-width: 200px;
}

@include media('<desktop') {
  .Header {
    flex-direction: column;
  }
  .SharedLinks {
    margin: 15px auto;
  }
}
