@import '../../media.module.scss';

.IncentivesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
}
.ContentTabs {
  width: 100%;
}
