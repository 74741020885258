.LoginFormTitle {
  text-align: center;
  margin-bottom: 40px !important;
}
.LoginFormTitleKinsu {
  text-align: center;
  margin-bottom: 25px !important;
}

.SignUpFormPassword {
  > label,
  p {
    color: #d32f2f !important;
  }
}

.LoginFormAdornment {
  display: flex;
  svg {
    position: relative;
    top: 3px;
  }
}

.LoginFormLink {
  text-decoration: none;
  margin-top: 8px !important;
}

.LoginFormActions {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 20px;
  }
}
