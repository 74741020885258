@import "../../../media.module.scss";

.RadioCardInput {
  border: 1px solid rgba(86, 90, 102, 0.1);
  border-radius: 5px;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 5px;

  img {
    width: 40px;
    height: auto;
    max-height: 40px;
  }
}

.RadioCardInputSmall {
  padding: 0px 8px;
  margin-bottom: 5px;

  img {
    width: 25px;
  }
}
