@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-LightItalic.ttf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-RegularItalic.ttf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

* {
  font-family: "Gelion", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}
