@import '../../../media.module.scss';

.UpdateKinsurerContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.UpdateForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;
  > button {
    width: 180px;
    margin-top: 20px;
  }
}

.FormInRow {
  display: flex;
  width: 100%;
  > :nth-child(2) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.BackdropContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OtherOptions {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $neutral4;
  margin-top: 30px;
  padding: 15px 0;
  > button {
    max-width: 200px;
    margin: 15px 0;
  }
}

.ResetPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  padding: 20px;
  box-sizing: border-box;
  > button {
    margin: 20px 0 10px 0;
    width: 100%;
    max-width: 200px;
  }
}

.SignUpFormPassword {
  > label,
  p {
    color: #d32f2f !important;
  }
}
