@import '../../../../media.module.scss';

.InventivesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 20px;
}
.IncentivesForm {
  width: 100%;
}
.Flex {
  display: flex;
  gap: 10px;
}
.Filter {
  flex: 1;
}
.IncentivesInputsContainer {
  display: flex;
  flex-direction: column;
}
.EditorContainer {
  border: 1px solid $neutral4;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px !important;
  label {
    margin-bottom: 10px;
  }
}
.Actions {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  button {
    width: 180px;
  }
}
