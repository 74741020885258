@import '../../../media.module.scss';

.DocumentCard {
  border: 1px solid $neutral5;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    background-color: $neutral3;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.DocumentCardTitle {
  padding: 10px;
  text-align: center;
  font-weight: 400 !important;
}

.OpenDoc {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-radius: 8px;
  height: 50px;
}

.StatusButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  button {
    width: 50%;
    margin: 0 5px 10px 5px;
  }
}

.WarningBand {
  width: 100%;
  background-color: $neutral3;
  padding: 5px 20px;
  box-sizing: border-box;
  display: flex;
}

.Band {
  width: 100%;
  padding: 2px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

@include media('<tablet') {
  .DocumentCard {
    width: 100%;
    max-width: 500px;
    margin-right: 0px;
    position: relative;
  }
}
