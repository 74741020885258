@import '../../../media.module.scss';

.EditStepForm {
  display: flex;
  gap: 20px;
}

.ColumnMaterial {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 15px 0;
  > button {
    width: 170px;
  }
  gap: 20px;
}
