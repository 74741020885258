@import '../../../media.module.scss';

.UpdateDialogContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 20px;
    box-sizing: border-box;
    button {
      margin: 20px 0 10px 0;
      width: 100%;
      max-width: 200px;
    }
}