@import '../../media.module.scss';

.TriggerAction {
  width: 200px;
  padding: 15px;
  border: 1px solid $neutral5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .Icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid $neutral5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
