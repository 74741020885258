@import '../../../media.module.scss';

.EmailContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.Form {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Action {
  width: 150px;
  align-self: flex-end;
}
