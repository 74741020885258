@import '../../../media.module.scss';

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 15px 0;
  > button {
    width: 170px;
  }
  gap: 20px;
}

.ActualContent {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.EditMedia {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.TextOff {
  color: $neutral5;
}

.ReadUrl {
  display: flex;
  gap: 10px;
  align-items: center;
  span {
    height: 24px;
  }
}

.CheckText {
    font-weight: 400 !important;
    > span {
      position: relative;
      top: 5px;
      left: 5px;
    }
  }
