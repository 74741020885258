@import '../../media.module.scss';

.ReportsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
}

.ActionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.ConfirmModal {
  padding: 20px;
  box-sizing: border-box;
  p {
    margin-bottom: 15px;
  }
  > div {
    display: flex;
    gap: 15px;
  }
}
