@import '../../../media.module.scss';

.CheckText {
  font-weight: 400 !important;
  > span {
    position: relative;
    top: 5px;
    left: 5px;
  }
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 15px 0;
  > button {
    width: 170px;
  }
  gap: 20px;
}
