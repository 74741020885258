@import '../../../media.module.scss';

.container {
  width: 220px;
  min-width: 220px;
  position: relative;
}

.containerModified {
  background-color: rgba($color: $warning1, $alpha: 0.2);
  border: 1px solid $warning3;
  border-radius: 10px;
}

.containerError {
  background-color: rgba($color: $error1, $alpha: 0.2);
  border: 1px solid $error5;
  border-radius: 10px;
}

.placeholder {
  padding: 10px 20px;
  box-sizing: border-box;
}

.update {
  position: absolute;
  right: 5px;
  top: 5px;
}

.logo {
  max-width: 100px;
  max-height: 40px;
  margin-bottom: 20px;
}

.itemHeader {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  margin-bottom: 10px;
}

.item {
  height: 50px;
  border-bottom: 1px solid $neutral4;
  padding: 10px 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.itemExtra {
  padding-top: 15px;
  height: 55px;
  justify-content: flex-start;
}

.versionSelectedInput {
  width: 100%;
  padding: 10px 5px 0px 5px;
  box-sizing: border-box;
}
