@import '../../../media.module.scss';

.HomeContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100% - 50px);
}

.KinsurersTable {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: scroll;
  padding: 0 20px 40px 20px;
  align-items: center;
  thead {
    background-color: $neutral3;
    border-radius: 10px !important;
  }
  td {
    max-width: 150px;
    word-wrap: break-word;
  }
  nav {
    margin-top: 20px;
  }
}

.SearchBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 20px;
}

.PaginationPosition {
  display: block;
  position: absolute;
  bottom: 10px;
}
