@import '../../media.module.scss';

.SelectorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $neutral5;
  padding: 10px 0px 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 170px;
  position: relative;
  > label {
    position: absolute;
    top: -10px;
    left: 5px;
    font-size: 14px;
    color: $neutral6;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.SelectorContainerHorizontal {
  width: 100% !important;
  > div {
    flex: 1;
    justify-content: space-around;
    flex-direction: row;
  }
}

.ErrorContainer {
  border: 1px solid $error5;
  > label {
    color: $error5;
  }
}

.Border {
  display: flex;
  flex-direction: column;
  > span {
    color: $error5;
    font-size: 14px;
    padding: 5px;
  }
}
