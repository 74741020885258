@import '../../../media.module.scss';

.HomeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.Header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.SearchBar {
  display: flex;
  width: 520px !important;
  margin-top: 5px;
}
.Table {
  height: 100%;
  padding: 0px 20px;
  overflow: auto;
}
.Pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
}
