@import '../../../media.module.scss';

.ResourceContainerOff {
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid $neutral4;
  margin-top: 15px;
}

.ResourceHeader {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.TextOff {
  color: $neutral6;
}

.ReadUrl {
  display: flex;
  gap: 10px;
  align-items: center;
  span {
    height: 24px;
  }
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 15px 0;
  > button {
    width: 170px;
  }
  gap: 20px;
}

.ConfirmModal {
  padding: 20px;
  box-sizing: border-box;
  p {
    margin-bottom: 15px;
  }
  > div {
    display: flex;
    gap: 15px;
  }
}
