@import '../../../media.module.scss';

.FormulaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  > :first-child {
    margin-bottom: 20px;
  }
}

.FormulaContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  > .Block:first-child {
    border-top: none;
  }
}

.Block {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border-top: 1px solid $neutral5;
  gap: 20px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0 15px 0;
  > button {
    width: 170px;
  }
  gap: 20px;
}

.StepsContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.Step {
  width: 250px;
  border-radius: 10px;
  border: 1px solid $neutral5;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: transparent;
  cursor: pointer;
  > img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: 3px solid $secondary;
  }
}

.StepSelected{
  background-color: $neutral3;
}

@include media('<desktop') {
  .Block {
    flex-direction: column;
  }
}
