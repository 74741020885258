@import '../../media.module.scss';

.General {
  max-width: 339px;
  margin-bottom: 25px;
}

.ShareQrCardTitle {
  background-color: $neutral0;
  padding: 5px 15px;
  color: $primary;
  font-weight: 400;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid $neutral4;
  border-bottom: none;
}

.ShareQrCodes {
  background-color: $neutral0;
  border: 1px solid $neutral4;
  padding: 15px;
}

.QrCode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CodeText {
  color: #24508f;
}

.DownloadContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 15px;
  background-color: $neutral0;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  border: 1px solid $neutral4;
  border-top: none;

  & > div {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
