@import '../../media.module.scss';

.HeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.HeaderPrincipal {
    flex: 1;
    display: flex;
    align-items: flex-start;
}
.HeaderChildren {
    flex: 1;
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
}
.ArrowIcon {
    position: relative !important;
    left: -8px;
}
