@import '../../../media.module.scss';

.HomeAppContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  background-color: $neutral3;
  height: 100%;
  > :first-child {
    margin-bottom: 20px;
  }
  > .Block:first-child{
    border-top: none;
  }
}

.Block {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border-top: 1px solid $neutral5;
  gap: 20px;
}

.CategoryList {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 16px;
}

.CategoryButton {
  padding: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 3px solid $secondary;
  color: $primary;
  width: 95px;
  height: 95px;
  text-align: center;
  background: none;
  word-break: normal;
  cursor: pointer;
}

.CategoryButtonSelected {
  background-color: $neutral3;
}
