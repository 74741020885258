@import '../../../media.module.scss';

.ContactKinsurerContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.ShowDocs {
  width: 100%;
  max-width: 180px;
  margin-right: 15px;
}

.NewContactButton {
  width: 200px !important;
  svg {
    position: relative;
    top: 3px;
    left: 10px;
  }
}

.TrackingAndCommentsContainer {
  margin-top: 15px;
  width: 100%;
  display: flex;
  > :last-child {
    margin-left: 15px;
  }
}

.TrackingElements {
  display: flex;
  flex-direction: column;
  flex: 8;
  .TrackingTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ContactTracking {
    width: 100%;
    margin: 20px 0;
  }
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $neutral0;
}

@include media('<desktop') {
  .Header {
    flex-direction: column;
  }
  .SharedLinks {
    margin: 15px auto;
  }
}
