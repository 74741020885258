@import '../../../media.module.scss';

.CheckDocumentsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.DocsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.BackdropContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}