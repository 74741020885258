@import '../../../media.module.scss';

.CommentsBlockContainer {
  flex: 4;
  display: flex;
  flex-direction: column;
  height: 500px;
  min-width: 350px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid $neutral4;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.HeaderButton {
  padding: 15px;
  box-sizing: border-box;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: $neutral4;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  > h6 {
    font-weight: 400;
  }
}

.CommentsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: scroll;
  > div {
    margin-bottom: 15px;
  }
  :last-child {
    margin-bottom: 0;
  }
  .Comment {
    padding: 10px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
  }
  .ButtonsInLine {
    display: flex;
    justify-content: flex-end;
    :first-child {
      border-right: 1px solid $primary;
    }
  }
  .TextButton {
    padding: 0px 6px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.InputContainer {
  display: flex;
  padding: 0 15px;
  box-sizing: border-box;
  .SendIcon {
    position: relative;
    top: 3px;
    left: 2px;
  }
}
