@import '../../../media.module.scss';

.PersonalInfo {
  height: fit-content;
  min-height: 150px;
  background-color: $neutral3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  padding: 15px;
  box-sizing: border-box;
}
.PrincipalInfo {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    margin-right: 15px;
  }
}
.Column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
  word-break: normal;
  // margin-right: 15px;
}
.MW {
  min-width: 260px;
}

.AlignIcon {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.ReadMore {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ReadMoreInfo {
  display: flex;
  flex-direction: column;
}

@include media('<desktop') {
  .PersonalInfo {
    margin-bottom: 10px;
  }
  .Column {
    margin: 15px 15px;
  }
}

@include media('<tablet') {
  .PrincipalInfo {
    flex-wrap: wrap;
  }
  .Column {
    margin: 15px 15px;
  }
}
