@import '../../../media.module.scss';

.ContactTrackingContainer {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $neutral4;
  padding-top: 5px;
  > div {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }
}

.ContactTrackingHeader {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  > span {
    margin-left: 20px;
  }
}

.ContactTrackingTitle {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.Icons {
  position: relative;
  top: 6px;
  left: 10px;
}

@include media('<desktop') {
}
