@import '../../media.module.scss';

.SharedInput {
  border: 1px solid $primary;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 50px;

  & > div {
    box-sizing: border-box;
  }

  & > div:first-child {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary;
    padding: 0px 15px;
    color: white !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  & > div:last-child {
    padding: 1px;
    box-sizing: border-box;

    button {
      background-color: transparent;
      margin: 0px 5px;
    }

    img {
      width: 30px;
    }
  }
}

.QrModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@include media('>desktop') {
  .IconLinkFirst {
    display: inline-flex !important;
  }

  .IconLinkSecond {
    display: none !important;
  }
}

@include media('<=desktop') {
  .SharedInput {
    flex-direction: column;
    height: 105px;

    & > div:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 10px;
      padding: 0;
    }
    & > div:first-child > * {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > div:last-child {
      display: flex;
      justify-content: center;
      height: 50px;
    }
  }

  .IconLinkFirst {
    display: none !important;
  }

  .IconLinkSecond {
    display: inline-flex !important;
  }
}

@include media('<tablet') {
  .SharedInput {
    & > div:first-child {
      p {
        font-size: 16px;
      }
    }
  }
}
