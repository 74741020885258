@import '../../../media.module.scss';

.InfoContainer {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.Details {
  position: relative;
  flex: 8;
  display: flex;
  flex-direction: column;
  background-color: $neutral2;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  > div {
    display: flex;
    gap: 15px;
    .Column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
.statusAndLeads {
  display: flex;
  gap: 20px;
  align-items: center;
}
.EditAction {
  position: absolute;
  right: 4px;
  top: 4px;
}
.AlignIcon {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.status {
  width: 70px;
  padding: 3px 7px;
  text-align: center;
  border-radius: 30px;
  margin-top: 5px;
}
.statusColorGray {
  border: 1px solid $neutral4;
  background-color: $neutral3;
}
.statusColorYellow {
  border: 1px solid #ffffd1;
  background-color: #ffffd1;
}
.statusColorGreen {
  border: 1px solid #e4ffad;
  background-color: #e4ffad;
}

.Separator {
  margin-right: 20px;
  border-bottom: 2px solid $neutral5;
  width: 100%;
}

.Additional {
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.refInfo {
  display: flex;
  flex-direction: column;
  background-color: $neutral2;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
}
