@import '../../../media.module.scss';

.UploadDocumentsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.Accordion {
    margin-top: 20px;
}

.DocHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    img {
        width: 50px;
    }
}

.DocBody {
    display: flex;
    align-items: center;
    gap: 20px;
}