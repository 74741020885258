@import '../../media.module.scss';

.ComingSoonContainer {
  margin-top: 70px;
}

.ImageContainer {
  width: 100%;
  margin: 20px auto;
}
.Image {
  max-width: 500px;
  height: 300px;
  background-color: $neutral0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

.Title {
  text-align: center;
  color: $neutral7;
  margin: 20px 0 0 0;
}
.Subtitle {
  text-align: center;
  color: $neutral7;
}
.Text {
  text-align: center;
  color: $neutral7;
  margin: 20px 0;
}
.GoHome {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

@include media('<=desktop') {
  .Image {
    max-width: 320px;
    height: 200px;
  }
}
