@import '../../../media.module.scss';

.form {
  max-width: 500px;
}

.RadioCardFieldset {
  margin-top: 20px !important;
}

.RadioCardGroup {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  & > div {
    flex: 1;
  }
  & > div:first-child {
    margin-right: 5px;
    box-sizing: border-box;
  }
  & > div:last-child {
    margin-left: 5px;
    box-sizing: border-box;
  }
}

.actions {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
