.Bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
        text-decoration: none;
        text-align: center;
        color: white;
    }
}